<template>
  <div class="my-login-page">
    <section>
      <div class="my-login-page-inner d-flex align-items-center justify-content-between" style="min-height: 100vh;max-width:82%">
        <div class="brand-name">
          <h6>
            ABAI DIGITAL
          </h6>
        </div>
        <div class="row">
          <div class="card-wrapper">
            <div class="brand">
              
            </div>
            <div class="card fat">
              <div class="card-body">
                <h3 class="card-title">Войти</h3>

                <form @submit.prevent="login" action="" class="my-login-validation" novalidate="">
                  <div v-if="errors" class="alert alert-danger" role="alert">
                    <p v-for="(error, field) in errors" :key="field">
                      {{ error[0] }}
                    </p>
                  </div>
                  <div class="form-link mt-3">
                    Нет аккаунта?
                    <router-link to="/register" class="forgot-pass">Регистрация</router-link>
                  </div>
                  <div class="form-group col-md-12 mt-5">
                    <input id="username" type="text" class="form-control" name="username"
                           v-model="form.username" placeholder="Электронная почта/логин" required autofocus>
                    <div class="invalid-feedback">
                      Логин неправильный
                    </div>
                  </div>

                  <div class="form-group col-md-12 mt-4">                    
                    <input id="password" type="password" class="form-control" name="password"
                           v-model="form.password" placeholder="Пароль" required data-eye>
                    
                    <div class="invalid-feedback">
                      Введите пароль
                    </div>
                  </div>


                  <div class="form-group d-flex align-items-end justify-content-between col-md-12 mt-4">

                    <div class="text-start">
                      <router-link to="/auth/recovery" class="forgot-pass">Забыли пароль?</router-link>
                    </div>
                    <Button label="Продолжить" type="submit"
                            :loading="loadingLogin" class="text-end"
                            />
                  </div>


                  
                  <p class="warning-text mt-5">
                    Я, пользователь информационной системы «ABAI DIGITAL», продолжая работу в системе, подтверждаю свое согласие об  ответственности за все осуществленные действия, в соответствии с Законом  РК 'О персональных данных и их защите'
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>


</template>
<script>
  import authService from "../services/auth.service";

  export default {
    name: "Login",
    data() {
      return {
        form: {
          username: '',
          password: ''
        },
        errors: null,
        loadingLogin: false
      }
    },
    methods: {
      async login() {
        if (!this.form.username.length || !this.form.password.length) {
          return this.$error({title: `Введите логин и пароль`})
        }

        this.loadingLogin = true

        const {success, errors} = await authService.login(this.form)

        this.loadingLogin = false

        if (success) {
          let redirect = this.$route.query.redirect

          if (redirect) {
            redirect = redirect.replace(window.location.origin, "")
            return await this.$router.push(redirect)
          }

          return await this.$router.push({name: 'home'})
        }

        this.errors = errors
        this.$error({title: `Неправильный логин или пароль`, text: ''})
      },
      async loginSSO(){
        await authService.loginSSO()
      },
      loginWithSAML() {
        // URL IdP, куда будет отправлен AuthnRequest
        const idpLoginURL = 'https://back.uib.kz/api/saml/initiate-sso';

        // URL, на который пользователь будет перенаправлен после успешной аутентификации
        const callbackURL = 'https://front.uib.kz/home';

        // Создаем SAML AuthnRequest
        const samlAuthnRequest = `
        <samlp:AuthnRequest
          xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol"
          ID="_request_id"
          Version="2.0"
          IssueInstant="2023-09-06T00:00:00Z"
          ProtocolBinding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect"
          AssertionConsumerServiceURL="${callbackURL}"
        >
          <saml:Issuer xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion">YourIssuer</saml:Issuer>
        </samlp:AuthnRequest>
      `;

        // Кодируем AuthnRequest для отправки через URL
        const encodedAuthnRequest = encodeURIComponent(btoa(samlAuthnRequest));
        console.log(encodedAuthnRequest, '123')

        // Перенаправляем пользователя на страницу IdP для входа
        window.location.href = `${idpLoginURL}?SAMLRequest=${encodedAuthnRequest}`;
      },

    }
  }
</script>

<style scoped>

</style>
